import { lazy, Suspense, useEffect, useState } from 'react';
import './Assets/App.css';
import Loader from './Components/Loader';
import { Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Login from './Pages/Login';
import PrivateRoute from './PrivateRoute';

import axios from 'axios';
import { SERVICE_SERVER } from './Utils/Settings';
import { sleep } from './Utils/Helper';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useStore } from './Utils/Store';

function App() {
  const Logout = lazy(() => import('./Pages/Logout'));
  const Home = lazy(() => import('./Pages/Home'));

  const updateToken = useStore((state) => state.updateToken);
  const [AppIsReady, setAppIsReady] = useState(false);
  const queryClient = new QueryClient();
  const location = useLocation();
  const url = `${SERVICE_SERVER}/getLoginInfo`;

  useEffect(() => {
    if (location.pathname === '/login') {
      setAppIsReady(true);
    } else if (!AppIsReady) {
      axios
        .post(url)
        .then(async (response) => {
          console.log('Calling getLoginInfo');
          const responseData = response.data;
          updateToken(responseData.token);
          await sleep(1000);
          setAppIsReady(true);
        })
        .catch(async () => {
          await sleep(1000);
          setAppIsReady(true);
        });
    }
  }, [AppIsReady, location, updateToken, url]);

  if (!AppIsReady) {
    return <Loader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
