import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Navigate, useLocation } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { SERVICE_SERVER } from '../Utils/Settings';
import Input from '../Components/Input';
import AppButton from '../Components/Button';
import { useStore } from '../Utils/Store';

interface FormValues {
  phone: string;
  password: string;
}
export interface LoginInfoResponse {
  token: string;
}

function Login() {
  const updateToken = useStore((state) => state.updateToken);
  const location = useLocation();
  const pageState = location.state as { from: Location };
  const from = pageState ? pageState.from.pathname : '/';

  const [isLogged, setIsLogged] = useState(false);
  const [error, setError] = useState('');

  const url = `${SERVICE_SERVER}/login`;
  const validationSchema = yup.object({
    password: yup.string().required('password is a required field'),
    phone: yup.string().required('user name is a required field'),
  });
  const initialValues = { phone: '', password: '' };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const onSubmit = (values: FormValues) => {
    setError('');
    axios
      .post<FormValues, AxiosResponse<LoginInfoResponse>>(url, values, {
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data;
        updateToken(responseData.token);
        setIsLogged(true);
      })
      .catch((responseError) => {
        const { response } = responseError;
        console.log(response);
        if (response) {
          if (response.status === 401) {
            setError('Incorrect username/password');
            return;
          }
          if (response.status === 422) {
            setError(response.data.message);
            return;
          }
          if (response.status === 403) {
            setError(
              'User is logged in another machine, please sigh out first'
            );
            return;
          }
        }
        setError("Can't connect to server, please try again");
      });
  };

  if (isLogged) {
    return <Navigate to={from} />;
  }

  return (
    <section
      className="vh-100 login-page-animation"
      style={{ backgroundColor: '#4b545c' }}
    >
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem' }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="/images/login.jpeg"
                    alt="login form"
                    className="img-fluid"
                    style={{
                      borderRadius: '1rem 0 0 1rem',
                      height: '100%',
                    }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <img
                          className="img-fluid mx-auto d-block w-50"
                          src="/images/logo.jpeg"
                          alt="Logo"
                          height="100"
                        />
                      </div>

                      <h5
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: '1px' }}
                      >
                        Sign into your account
                      </h5>
                      <Input
                        id="phone"
                        name="phone"
                        text="Phone"
                        type="text"
                        placeholder=""
                        control={control}
                        errors={errors}
                      />

                      <Input
                        id="password"
                        name="password"
                        text="Password"
                        type="password"
                        control={control}
                        errors={errors}
                      />
                      <div className="d-grid gap-2">
                        <AppButton
                          type="submit"
                          theme="primary"
                          className="mt-2"
                        >
                          Login
                        </AppButton>
                      </div>
                      {error && (
                        <div
                          className="alert alert-danger mt-3 text-center"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
