import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from '@hookform/error-message';

type InputProps = {
  id: string;
  type:
    | 'email'
    | 'password'
    | 'number'
    | 'datetime'
    | 'time'
    | 'select'
    | 'textarea'
    | 'text'
    | 'checkbox';
  name: string;
  placeholder?: string;
  text: string;
  errors?: any;
  control: any;
  disabled?: boolean;
};

const Input = ({
  id,
  type,
  name,
  placeholder,
  text,
  errors,
  control,
  disabled = false,
}: InputProps) => {
  const renderControl = ({ field }: any) => {
    return (
      <Form.Control
        isInvalid={!!errors?.[name]}
        type={type}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
      />
    );
  };

  return (
    <Form.Group>
      <Form.Label htmlFor={id}>{text}</Form.Label>
      <Controller name={name} control={control} render={renderControl} />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => (
          <Form.Control.Feedback type="invalid">
            {message}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
};

export default Input;
