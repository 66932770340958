import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Loader from './Components/Loader';
import { useStore } from './Utils/Store';

const PrivateRoute = () => {
  const location = useLocation();
  const token = useStore((state) => state.token);

  const isLogged = () => {
    if (token) {
      return true;
    }
    return false;
  };

  if (isLogged() !== true) {
    if (location.pathname === '/logout') {
      return <Navigate replace to="/login" />;
    }
    return <Navigate replace to="/login" state={{ from: location }} />;
  }  
  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
};

export default PrivateRoute;
