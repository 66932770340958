export const SERVICE_SERVER =
  process.env.NODE_ENV === 'development'
    ? 'http://ascomobileapp.local/referral'
    : 'https://mobile.aljawdalab.com/referral';

export interface Dictionary {
  [key: string]: string;
}

export type SelectType = { value: number; label: string };
export const defaultPageSize = 30;
export const PageSizeOptions: SelectType[] = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];
